<template>
	<div class="home bg-dark-subtle h-100">
		<GlobalMenu />
		<!-- <pre class="opacity-50 text-info-emphasis" style="font-size: x-small;">{{ windows }}</pre> -->
		<div class="stack h-100">
			<ModalWindow :ref="`window_${win.id}`" v-for="(win, index) in windows" :key="win.id"
				:id="`window-` + win.id" :properties="windows[index]" />
		</div>

		<DockBar></DockBar>
	</div>
</template>
<script>
import GlobalMenu from '@/components/GlobalMenu.vue';
import ModalWindow from '@/components/ModalWindow.vue';
import DockBar from '@/components/DockBar.vue';

// const $ = window.$;
export default {
	name: 'HomeView',
	components: {
		GlobalMenu,
		ModalWindow,
		DockBar
	},

	computed: {
		windows() {
			return this.$store.state.windows;

			// return this.windows?.filter(win => win !== null);
		}
	},

	methods: {
		toggle(winId) {
			alert('HomeView.toggle :: DEPRECATED');
			const modal = this.$refs[`window_${winId}`][0];
			modal.toggle();
			return winId;
		},
		show(winId) {
			alert('HomeView.show :: DEPRECATED');
			console.log('HomeView.show', winId);
			const modal = this.$refs[`window_${winId}`][0];
			modal.show();
		},
		hide(winId) {
			alert('HomeView.hide :: DEPRECATED');
			console.log('HomeView.hide', winId);
			const modal = this.$refs[`window_${winId}`][0];
			modal.hide();
		}
	},
	mounted() {
		this.$root.view = this;
	}
};
</script>
