import { createStore } from 'vuex';

import apps from './apps';
export default createStore({
	state: {
		apps: [],
		windows: [],
		prefs: null
	},
	getters: {
		getAppById: (state) => (id) => {
			return state.apps.find(app => app.id === id);
		},
		getAppsByCategory: (state) => (catId) => {
			return state.apps.filter(app => app.catId === catId);
		}
	},
	mutations: {
	},
	actions: {
		async getAppList(context) {
			return new Promise((resolve) => {
				setTimeout(() => {
					context.state.apps = apps;
					resolve(context.state.apps);
				}, 500);
			});
		},
		async getPreferences(context) {
			return new Promise((resolve) => {
				if (!context.state.prefs) {
					setTimeout(() => {
						context.state.prefs = {
							theme: 'light'
						};
						resolve(context.state.prefs);
					}, 500);
				} else {
					resolve(context.state.prefs);
				}
			});
		}
	},
	modules: {
	}
});
