<template>
	<div class="serene-view p-1">
        <iframe
            src="https://app.gpt-trainer.com/widget/6f1d21d39dc64b5482febed99cf9a207"
            width="100%"
            height="712px"
            frameborder="0"
        ></iframe>
	</div>
</template>
<script>

export default {
	name: 'SerenetView',
	data() {
		return {
		};
	},
	mounted() {
	}
};
</script>
