<template>
	<div class="global-menu m-0 p-0">
		<nav class="navbar navbar-expand-lg bg-body-tertiary">
			<div class="container-fluid">

				<app-browser class=""></app-browser>

				<router-link class="navbar-brand" to="/">Appcropolis<span class="text-warning">OS</span></router-link>

				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
					aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>

				<div class="collapse navbar-collapse" id="navbarNavDropdown">
					<ul class="navbar-nav">
						<!-- app menu -->
						<li class="nav-item dropdown" v-if="$root.windows?.length && $root.current.win">
							<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">{{
								$root.current.win.title }}</a>
							<ul class="dropdown-menu">
								<li>
									<a class="dropdown-item" href="#" @click="aboutCurrentApp">About</a>
								</li>
								<li>
									<a class="dropdown-item" href="#" @click="hideCurrentApp">Hide</a>
								</li>
								<div v-if="windows.length" class="dropdown-divider"></div>
								<li>
									<a class="dropdown-item" href="#" @click="closeCurrentApp">Close</a>
								</li>
							</ul>
						</li>

						<li class="nav-item dropdown" v-if="$root.windows?.length && $root.current.win">
							<a class="nav-link dropdown-toggle" href="#" aria-expanded="false"
								data-bs-toggle="dropdown">View</a>
							<ul class="dropdown-menu">
								<li>
									<a class="dropdown-item" href="#" @click="showCurrentApp">Show</a>
								</li>
								<li>
									<a class="dropdown-item" href="#" @click="hideCurrentApp">Hide</a>
								</li>
								<div v-if="windows.length" class="dropdown-divider"></div>
								<li>
									<a class="dropdown-item text-muted pointer-events-none" href="#"
										@click.prevent="">Fullscreen</a>
								</li>
							</ul>
						</li>

						<li class="nav-item dropdown">
							<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
								aria-expanded="false">Windows</a>
							<ul class="dropdown-menu">
								<li v-for="(win, index) in windows" :key="index">
									<a class="dropdown-item" href="#"
										@click="windows[index].visible === true ? $root.moveWindowToTop(win.id) : restore(win)"
										:class="[windows[index].visible === true ? 'text-muted' : 'fw-medium']">{{ win.title
										}}</a>
								</li>
								<div v-if="windows.length" class="dropdown-divider"></div>
								<li v-if="windows.length">
									<a class="dropdown-item" href="#" @click="$root.closeAllWindows">Close All</a>
								</li>

								<div v-if="windows.length === 0" class="dropdown-item">
									<small class="text-muted">No windows open</small>
								</div>
							</ul>
						</li>
					</ul>
				</div>

				<button class="btn btn-outline-success" @click="$root.about">v.0.1.0</button>
			</div>
		</nav>
	</div>
</template>

<script>
import AppBrowser from './AppBrowser.vue';

export default {
	name: 'GlobalMenu',
	components: {
		AppBrowser
	},

	computed: {
		windows() {
			return this.$root.windows;
		}
	},

	methods: {
		toggle(winId) {
			alert('GlobalMenu.toggle :: DEPRECATED');
			// const modal = this.$refs[`window_${winId}`][0];
			// modal.toggle();
			// return winId;
		},

		restore(win) {
			this.$root.showWindow(win.data.id);
			setTimeout(() => {
				this.$root.moveWindowToTop(win.id);
			}, 200);
		},
		showCurrentApp() {
			const appId = this.$root.current.win.data.id;
			this.$root.showWindow(appId);
		},
		hideCurrentApp() {
			const appId = this.$root.current.win.data.id;
			this.$root.hideWindow(appId);
		},
		closeCurrentApp() {
			const appId = this.$root.current.win.data.id;
			this.$root.closeWindow(appId);
		},
		aboutCurrentApp() {
			const appId = this.$root.current.win.data.id;
			setTimeout(() => {
				this.$root.aboutApp(appId);
			}, 200);
		}
	}
};
</script>

<style scoped>
.global-menu {
	display: block;
}

.navbar {
	z-index: 1100;
}
</style>
