export default [
	{
		title: 'Finder',
		description: 'Finder A tool for email management with summaries, draft responses, and inbox sorting.',
		category: 'Productivity',
		catId: 1,
		id: 1,
		appIcon: 'finder.png',
		catIcon: 'cat-icon.svg',
		urls: {
			main: '/default'
		}
	},
	{
		title: 'AppStore',
		description: 'AppStore A tool to research nocode projects for efficient development.',
		category: 'Productivity',
		catId: 1,
		id: 2,
		appIcon: 'appstore.png',
		catIcon: 'cat-icon.svg',
		urls: {
			main: '/default'
		}
	},
	{
		title: 'Messenger',
		description: 'Messenger A tool to crafts personalized Upwork proposals.',
		category: 'Productivity',
		catId: 1,
		id: 3,
		appIcon: 'message.png',
		catIcon: 'cat-icon.svg',
		urls: {
			main: '/default'
		}
	},
	{
		title: 'Music',
		description: 'Music A tool for online course integration directly onto existing websites.',
		category: 'Productivity',
		catId: 1,
		id: 4,
		appIcon: 'music.png',
		catIcon: 'cat-icon.svg',
		urls: {
			main: '/default'
		}
	},
	{
		title: 'Mail',
		description: 'Mail A document collaboration platform for knowledge sharing and management through interactive documents and wikis.',
		category: 'Productivity',
		catId: 1,
		id: 5,
		appIcon: 'mail.png',
		catIcon: 'cat-icon.svg',
		urls: {
			main: '/default'
		}
	},
	{
		title: 'Photos',
		description: 'Photos A tool to automate sales and account management by centralizing communication.',
		category: 'Productivity',
		catId: 1,
		id: 6,
		appIcon: 'photos.png',
		catIcon: 'cat-icon.svg',
		urls: {
			main: '/default'
		},
		canva: {
			minWidth: 800,
			minHeight: 480
		}
	},
	{
		title: 'Pexels',
		description: 'A SDK to create custom dashboards and data visualizations from multiple data sources.',
		category: 'Productivity',
		catId: 1,
		id: 7,
		appIcon: 'pexels.png',
		catIcon: 'cat-icon.svg',
		urls: {
			main: 'https://os.appcropolis.net/extensions/pexels/'
		},
		canva: {
			minWidth: 800,
			minHeight: 720
		}
	},
	{
		title: 'JSON Editor',
		description: 'A tool to optimize email management with daily summaries and unsubscribe options.',
		category: 'Productivity',
		catId: 1,
		id: 8,
		appIcon: 'json-editor.png',
		catIcon: 'cat-icon.svg',
		urls: {
			main: 'https://os.appcropolis.net/extensions/json-editor/'
		},
		canva: {
			minWidth: 800,
			minHeight: 550
		}
	},
	{
		title: 'AOS Terminal',
		description: 'AppStore A tool to research nocode projects for efficient development.',
		category: 'Productivity',
		catId: 1,
		id: 9,
		appIcon: 'js-terminal.png',
		catIcon: 'cat-icon.svg',
		urls: {
			main: 'https://os.appcropolis.net/extensions/js-terminal/'
		},
		canva: {
			minWidth: 800,
			minHeight: 480
		}
	},
	{
		id: 10,
		title: 'Serene',
		description: 'AI Virtual Assistant.',
		appIcon: 'app-icon.svg',
		catIcon: 'cat-icon.svg',
		category: 'AI',
		catId: 2,
		urls: {
			main: '/serene'
		},
		canva: {
			minWidth: 440,
			minHeight: 720
		}
	},
	{
		id: 11,
		title: 'App 5',
		description: 'A tool to optimize email management with daily summaries and unsubscribe options.',
		appIcon: 'app-icon.svg',
		catIcon: 'cat-icon.svg',
		category: 'Others',
		catId: 3,
		urls: {
			main: '/default'
		}
	}
];
