<template>
	<div id="app-browser" class="nav-item dropdown me-3">
		<a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
			<span class="material-symbols-outlined">
				apps
			</span>
		</a>
		<div class="app-browser dropdown-menu shadow" @click.stop="">
			<div class="d-flex flex-column app-browser-content">
				<div class="container-fluid flex-fill">
					<div class="row">
						<div class="col-4">
							<div class="widget pt-3">
								<h3 class="fs-6 ms-3 mb-3">Categories</h3>
								<ul class="list-group list-group-flush">
									<li class="list-group-item border-0" v-for="(cat, index) in categories" :key="index">
										<img class="float-start me-2" :src="require(`@/assets/${cat.catIcon}`)"
											alt="cat-icon" style="width: 24px; height: 24px;">
										<a class="text-decoration-none" href="#"
											@click.stop="getAppsByCategoryId(cat.catId)">{{ cat.category }}</a>
									</li>
								</ul>
							</div>
						</div>
						<div class="col-8">
							<div class="widget pt-3">
								<h3 class="fs-6 ms-3 mb-3">Applications</h3>
								<ul class="list-group list-group-flush">
									<li class="list-group-item border-0 app-item" v-for="(app, index) in current.apps"
										:key="index" @click.stop="openApp(app)">
										<img class="float-start me-2" :src="require(`@/assets/icons/dock/${app.appIcon}`)"
											alt="cat-icon" style="width: 64px; height: 64px;">
										<a class="text-decoration-none" href="#">{{ app.title
										}}</a>
										<small class="d-block text-muted">{{ app?.description || '...' }}</small>
									</li>
								</ul>
							</div>

							<!-- <pre>{{current.category}}</pre> -->
						</div>
					</div>
				</div>
				<div class="container-fluid">
					<div class="row">
						<form class="d-flex" role="search">
							<input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
							<button class="btn btn-outline-success" type="submit">Search</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import appsList from '@/apps-list.js';
const $ = window.$;
const bootstrap = window.bootstrap;

export default {
	name: 'AppBrowser',
	components: {
	},

	data() {
		return {
			apps: [],
			current: {
				category: null,
				apps: []
			},
			isShown: false,
			appsList: []
		};
	},

	computed: {
		categories() {
			const categories = [];
			const dict = {};
			this.apps.forEach((app) => {
				if (typeof dict[app.category] === 'undefined') {
					dict[app.category] = true;
				} else {
					return false;
				}

				categories.push({
					category: app.category,
					catIcon: app.catIcon,
					catId: app.catId
				});
			});

			return categories;
		}
	},

	watch: {
		isShown(newValue) {
			if (newValue) {
				console.log('Class "active" was added');
			} else {
				console.log('Class "active" was removed');
			}
		}
	},

	methods: {
		// async getApps() {
		// 	return new Promise((resolve) => {
		// 		const apps = appsList;

		// 		setTimeout(() => {
		// 			resolve(apps);
		// 		}, 500);
		// 	});
		// },
		async getAppsByCategoryId(id) {
			// console.log('id', id);
			if (this.apps?.length < 1) {
				await this.getApps().then((apps) => {
					this.apps = apps;
				});
			}

			this.current.apps = this.apps.filter((app) => {
				return app.catId === id;
			});

			this.current.category = this.categories.filter((cat) => {
				return cat.catId === id;
			});

			return this.current.apps;
		},
		openApp(app) {
			// console.log('AppBrowser.openApp', app);
			$('#app-browser .dropdown-menu').removeClass('reveal');
			setTimeout(() => {
				this.close();
				this.$root.openApp(app);
			}, 200);
		},

		watchIsShown() {
			$('#app-browser .dropdown-toggle').on('show.bs.dropdown', () => {
				// console.log('show.bs.dropdown');
				setTimeout(() => {
					$('#app-browser .dropdown-menu').addClass('reveal');
				}, 100);
			});

			$('#app-browser .dropdown-toggle').on('hidden.bs.dropdown', () => {
				// console.log('hidden.bs.dropdown');
				setTimeout(() => {
					$('#app-browser .dropdown-menu').removeClass('reveal');
				}, 100);
			});
		},

		close() {
			const toggler = $('#app-browser .dropdown-toggle')[0];
			const dropdown = new bootstrap.Dropdown(toggler);
			dropdown.hide();
		}
	},

	created() {
		// console.log('created', $);
	},

	async mounted() {
		this.$root.appBrowser = this;

		this.apps = await this.$store.dispatch('getAppList');
		// await this.getApps().then((apps) => {
		// 	this.apps = apps;
		// });

		this.current.category = this.categories[0] || null;
		await this.getAppsByCategoryId(this.current?.category?.catId);

		this.watchIsShown();

		// console.log('apps', this.apps);
		// console.log('categories', this.categories);
		// console.log('current.category', this.current.category);
	}
};
</script>

<style scoped>
.app-browser {
	width: 720px;
	min-height: 640px;
}

.app-browser-content {
	min-height: 620px;
}

.dropdown-menu {
	opacity: 0;
	transform: scale(0.9);
	transform-origin: top left;
	transition: opacity 0.2s, transform 0.2s;
}

.dropdown-menu.reveal {
	transform: scale(1);
	opacity: 1;
}

.list-group-item {
	transition: background-color 0.2s;
}

.list-group-item:hover {
	background-color: #f8f9fa;
}

.list-group-item.app-item:hover {
	cursor: pointer;
}
</style>
