import { createRouter, createWebHistory } from 'vue-router';
import DesktopView from '../views/DesktopView.vue';
import DefaultView from '../views/DefaultView.vue';
import SereneView from '../views/SereneView.vue';

const routes = [
	{
		path: '/',
		name: 'home',
		component: DesktopView
	},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	},
	{
		path: '/default',
		name: 'defaultView',
		component: DefaultView
	},
	{
		path: '/serene',
		name: 'sereneView',
		component: SereneView
	},
];

const router = createRouter({
	mode: 'history',
	history: createWebHistory(),
	routes
});

export default router;
