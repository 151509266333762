<template>
	<div class="default-view p-3">
		<h1>{{ now }}</h1>
	</div>
</template>
<script>

export default {
	name: 'DefaultView',
	data() {
		return {
			now: null
		};
	},
	mounted() {
		const now = new Date();
		const hours = now.getHours(); // Get the hours
		const minutes = now.getMinutes(); // Get the minutes
		const seconds = now.getSeconds(); // Get the seconds
		const time = hours + ":" + minutes + ":" + seconds; // Create the time string
		this.now = time;
	}
};
</script>
