<template>
	<div class="dock bg-opacity-25 bg-light">
		<button class="icon px-1" v-for="(app, index) in apps" :key="index"
			:class="app.title.toLowerCase().replace(' ', '-')" @click="$root.openApp(app)">
			<img :src="require(`@/assets/icons/dock/${app.appIcon}`)" alt="Finder Logo" class="hidden" />
			<span class="app-name">{{ app.title }}</span>
			<div class="point" v-if="opened.includes(app.id)">
			</div>
		</button>
	</div>
</template>

<script>

export default {
	name: 'DockBar',
	data() {
		return {
			appList: []
		};
	},

	computed: {
		apps() {
			// the first 8 apps will be shown in the dock
			return this.appList.slice(0, 10);
		},

		opened() {
			window.doc = this;
			const wins = this.$store.state.windows;
			// const opened = wins.filter((win) => win.visible);
			return wins.map((win) => win.data.id);
		}
	},
	async mounted() {
		this.$root.dock = this;
		this.appList = await this.$store.dispatch('getAppList');
	}
};
</script>

<style scoped>
.dock {
	display: flex;
	align-items: center;
	position: fixed;
	padding: 0.2rem 0.5rem 0 0.5rem;
	bottom: 10px;
	left: 50%;
	height: 65px;
	transform: translateX(-50%);
	backdrop-filter: blur(20px);
	border: 1px solid var(--color-white-200);
	border-radius: 14px;
	/* box-shadow: 0 0 30px rgba(0, 0, 0, 0.555); */
	z-index: 10;
}

@media screen and (max-width: 900px) {
	.dock {
		border-radius: 20px;
	}
}

.dock .point {
	width: 6px;
	height: 6px;
	margin: 0;
	background: #f08080;
	border-radius: 50%;
	/* display: none; */
	animation: top-to-bottom 0.3s;
	transition: 0.3s;
	position: absolute;
	/* transform: translateY(100px); */
	transform: translateY(23px);
}

.dock .point.opened {
	transform: translateY(23px);
}

.dock .column {
	width: 1px;
	height: 45px;
	margin: 0 4px;
	background: var(--color-white-400);
}

.dock .icon {
	position: relative;
	display: grid;
	place-items: center;
	background: none;
	border: none;
	outline: none;
	transition: all 0.3s;
	transform: translate3d(0px, 0%, 0px);
	-webkit-tap-highlight-color: transparent;
}

.dock .icon .app-name {
	font-size: 13px;
	position: absolute;
	background-color: rgba(255, 255, 255, 0.39);
	top: -25%;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	width: 95px;
	height: 22px;
	padding: 0 3px;
	border-radius: 8px;
	font-family: "lexend", sans-serif;
	font-weight: 700;
	opacity: 0;
	pointer-events: none;
}

.dock .icon:hover .app-name {
	opacity: 1;
	transition: 0.3s;
}

/*
.dock .icon.finder:hover::after {
	content: "Finder";
}

.dock .icon.appstore:hover::after {
	content: "App Store";
}

.dock .icon.messenger:hover::after {
	content: "Messenger";
}

.dock .icon.music:hover::after {
	content: "Music";
}

.dock .icon.mail:hover::after {
	content: "Mail";
}

.dock .icon.photos:hover::after {
	content: "Photos";
} */

.dock .icon img {
	width: 3.0rem;
	transition: 0.2s;
	transform: translateY(-2.5px);
	transform-origin: bottom center;
}

.dock .icon:hover img {
	margin: 0 0 28px 0;
	width: 5.0rem;
	transform-origin: bottom center;
}

.dock .icon:hover+.icon img {
	width: 4.0rem;
	margin: 0 0 14px 0;
}

.dock .icon img:active {
	filter: brightness(0.6) drop-shadow(0 0 10px #2c2c2c);
}
</style>
