<template>
	<div ref="modal" class="modal draggable pointer-events-none position-absolute" tabindex="-1">
		<div class="d-none">{{ app = properties.data}}</div>
		<div class="modal-dialog shadow pointer-events-none rounded-5 minimized" style="top: 20%">
			<div class="modal-content">
				<div class="modal-header py-2">
					<div class="win-buttons">
						<span class="bar-button badge text-bg-danger rounded-circle p-0"
							style="margin-right: 7px; width: 14px; height: 14px;"
							@click="$root.closeWindow(app.id)">&nbsp;</span>
						<span class="bar-button badge text-bg-warning rounded-circle p-0"
							style="margin-right: 7px; width: 14px; height: 14px;"
							@click="$root.hideWindow(app.id)">&nbsp;</span>
						<span class="bar-button badge text-bg-success rounded-circle p-0"
							style="margin-right: 7px; width: 14px; height: 14px;" @click.prevent="maximize">&nbsp;</span>
					</div>
					<h5 class="modal-title text-center fs-6 flex-fill user-select-none">{{ properties?.title }}
					</h5>
					<div style="width: 70px;"></div>
				</div>
				<div class="modal-body text-bg-warning d-none">
					<p class="mb-0">{{ app.description }}</p>
				</div>
				<div class="modal-body p-0">
					<iframe :src="app?.urls?.main" frameborder="0"
						:style="{ 'min-height': app?.canva?.minHeight + 'px' || '320px' }" class="pointer-events-auto"
					></iframe>
				</div>
				<div class="modal-footer py-1">
					<button type="button" class="btn btn-sm btn-light fw-medium text-primary text-uppercase px-1"
						@click.prevent="popup">
						<span class="material-icons float-start" style="font-size: 20px;">
						open_in_new
						</span>
					</button>
					<!-- <button type="button" class="btn btn-sm btn-light fw-medium text-primary text-uppercase px-1"
						@click="$root.closeWindow(app.id)">
						<span class="material-icons float-start">
						cancel_presentation
						</span>
					</button> -->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
const $ = window.$;
const bootstrap = window.bootstrap;
export default {
	name: 'ModalWindow',
	props: {
		properties: {
			type: Object,
			default() {
				return {
					id: 0,
					title: 'Window',
					data: {
						title: "Boxletters",
						description: 'This is a window',
						appIcon: "app-icon.svg",
						catIcon: "cat-icon.svg",
						catId: 1,
						category: "Productivity",
						id: 0
					}
				};
			}
		}
	},
	components: {
	},

	data() {
		return {
			modalObj: null
			// properties: {
			// 	id: 0,
			// 	title: 'Window',
			// 	data: {
			// 		title: "Boxletters",
			// 		description: 'This is a window',
			// 		appIcon: "app-icon.svg",
			// 		catIcon: "cat-icon.svg",
			// 		catId: 1,
			// 		category: "Productivity",
			// 		id: 0
			// 	}
			// }
		};
	},
	watch: {
		properties: {
			handler() {
				if (this.properties?.visible) {
					// console.log('[' + this.properties.title + ']watch:properties:show', this.properties?.visible, this.properties);
					this.show();
				} else if (this.properties?.visible === false) {
					// console.log('[' + this.properties.title + ']watch:properties:hide', this.properties?.visible, this.properties);
					this.hide();
				}
			},
			deep: true
		}
	},

	methods: {
		modal() {
			// console.log('\tModalWindow.modal', this.properties.id);
			return $(this.$el);
		},

		toggle() {
			console.log('ModalWindow.toggle', this.properties.id);
			alert('ModalWindow.toggle :: DEPRECATED');
			// console.log('ModalWindow.toggle', this.properties.id);
			// this.modal().find('.modal-dialog').toggleClass('minimized');
		},

		show() {
			// console.log('ModalWindow.show', this.properties.id);
			// console.log('ModalWindow.show', this.properties);

			this.modal().modal('show');

			setTimeout(() => {
				this.modal().find('.modal-dialog').removeClass('minimized');
			}, 100);
		},

		hide() {
			// console.log('ModalWindow.hide', this.properties.id);
			this.modal().find('.modal-dialog').addClass('minimized');

			setTimeout(() => {
				this.modal().modal('hide');
			}, 500);
		},

		popup() {
			const url = this.properties.data.urls.main;
			const win = window.open(url, '_blank');
			return win;
		},
		minimize() {
			// console.log('ModalWindow.minimize', this.properties.id);
			this.modal().find('.modal-dialog').addClass('minimized');
		},

		maximize(/* action */) {
			// const method = action ? 'addClass' : 'removeClass';
			// this.modal().find('.modal-dialog')[method]('minimized');
			alert('maximize not implemented yet!');
		},

		close() {
			// console.log('ModalWindow.close', this.properties.id);
			this.modal().find('.modal-dialog').addClass('minimized');
			setTimeout(() => {
				this.$root.closeWindow(this.properties.id);
			}, 500);
		},

		instance() {
			// console.log('ModalWindow.instance', this.properties.id);
			const modalEl = this.$refs.modal;

			if (modalEl) {
				const modal = new bootstrap.Modal(modalEl, {
					backdrop: false
				});
				return modal;
			} else {
				return null;
			}
		}
	},

	mounted() {
		// console.log('ModalWindow.mounted', this.properties.id);
		this.$root.win = this;
		this.modalObj = this.instance();

		setTimeout(() => {
			this.modal().find('.modal-content').click(() => {
				this.$root.moveWindowToTop(this.properties.id);
			});

			this.modal().find('.modal-dialog').draggable({
				cursor: 'move',
				handle: '.modal-header',
				stop: (event, ui) => {
					const dialog = this.modal().find('.modal-dialog');
					dialog.removeClass('shadow-lg').addClass('shadow');
					dialog.find('iframe').removeClass('dragging');
				},
				start: (event, ui) => {
					const dialog = this.modal().find('.modal-dialog');
					dialog.removeClass('shadow').addClass('shadow-lg');
					dialog.find('iframe').addClass('dragging');
					this.$root.moveWindowToTop(this.properties.id);
				}
			});
		}, 100);

		// setTimeout(() => {
		// 	this.$root.trace(this, 'ModatWindow');
		// }, 2000);
	}
};
</script>

<style scoped>

.modal:not(.top-window) iframe {
	pointer-events: none;
}
iframe {
	width: 100%;
	height: 320px;
	border: none;
}

iframe.dragging {
	pointer-events: none;
}

.modal:not(.top-window) .win-buttons {
	filter: grayscale(70%);
	opacity: 0.2;
}

.modal .modal-content {
	transition: filter 0.2s;
	filter: brightness(100%);
}

.modal:not(.top-window) .modal-content {
	filter: brightness(95%);
}

.modal-dialog {
	opacity: 1;
	transform: scale(1);
	/* Ensure transform is also defined in the default state for a smooth transition */
	transition: transform 0.15s, opacity 0.15s, box-shadow 0.15s;
	/* Add box-shadow to the transition */
	/* Example box-shadow for demonstration */
	/* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
}

.modal-dialog.minimized {
	opacity: 0;
	transform: scale(0.7) !important;
	/* Avoid using !important if possible */
	/* Adjust or remove box-shadow as needed for the transition */
	/* box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05); */
}

.modal-header {
	cursor: move;
}

.modal-body {
	display: contents;
}

.bar-button {
	cursor: pointer;
}
</style>
