<template>
	<router-view ref="view" />
</template>

<script>
// const bootstrap = window.bootstrap;
const $ = window.$;
export default {
	name: 'App',
	components: {

	},
	data() {
		return {
			ready: false,
			view: null,
			windows: this.$store.state.windows || [],
			current: {
				last: null,
				win: null
			},
			zIndexes: [],
			constants: {
				TOP_LIMIT: 55
			}
		};
	},

	methods: {
		/**
		 * Show the about dialog
		 */
		about() {
			alert('Appcropolis OS v.0.1.0');
		},

		/**
		 * Open an app
		 * @param {Object} app
		 * @param {Object} app.id	The app's id
		 * @param {Object} app.title	The app's title
		 * @param {Object} app.description	The app's description
		 * @param {Object} app.appIcon	The app's icon
		 * @param {Object} app.catIcon	The app's category icon
		 * @param {Object} app.catId	The app's category id
		 * @param {Object} app.category	The app's category
		 *
		 * @returns {void}
		 */
		openApp(app) {
			const isAppOpen = this.isAppOpen(app.id);
			const isVisible = this.isVisible(app.id);

			if (isAppOpen && isVisible) {
				// bring a to top
				// console.log('App.openApp :1: bring to top', app.id);
				const win = this.windows.find((win) => win.data.id === app.id);
				this.moveWindowToTop(win.id);
			} else if (isAppOpen && !isVisible) {
				// show app
				// console.log('App.openApp :2: show', app.id);
				const win = this.windows.find((win) => win.data.id === app.id);
				!win.visible && (win.visible = true);
				this.showWindow(app.id);
			} else {
				// add the app
				// console.log('App.openApp :3: add', app.id);
				this.addWindow(app);
			}
		},

		isVisible(appId) {
			const win = this.windows.find((win) => win.data.id === appId);
			const isVisible = !!win && win.visible;
			return isVisible;
		},

		isAppOpen(appId) {
			// console.log('isAppOpen', appId);
			const win = this.windows.find((win) => win.data.id === appId);
			const open = !!win;
			return open;
		},

		isWindowOpen(winId) {
			return this.windows.findIndex((win) => win.id === winId) > -1;
		},

		addWindow(app) {
			const id = new Date().getTime();
			this.zIndexes.push(id);
			this.windows.push({
				id: id,
				visible: null,
				title: app.title || 'Untitled',
				data: app || {}
			});

			setTimeout(() => {
				const last = this.windows[this.windows.length - 1];
				last.visible = true;
			}, 100);

			setTimeout(() => {
				this.moveWindowToTop(id);
			}, 200);
		},

		showWindow(appId) {
			const win = this.windows.find((win) => win.data.id === appId);
			win && (win.visible = true);
		},

		hideWindow(appId) {
			const win = this.windows.find((win) => win.data.id === appId);

			// console.log('%cHIDING WINDOW', 'color: red; font-weight: bold;', win.title);
			win && (win.visible = false);
		},

		aboutApp(appId) {
			const app = this.$store.state.apps.find((app) => app.id === appId);
			alert(app.title + ' v.0.1.0');
		},
		closeWindow(appId) {
			const win = this.windows.find((win) => win.data.id === appId);
			// console.log('%cCLOSING WINDOW', 'color: red; font-weight: bold;', win.title);
			this.hideWindow(appId);
			setTimeout(() => {
				// remove window from windows
				const index = this.windows.findIndex((win) => win.data.id === appId);
				this.windows.splice(index, 1);

				// remove window id from zIndexes
				const zIndexIndex = this.zIndexes.findIndex((id) => id === win.id);
				this.zIndexes.splice(zIndexIndex, 1);

				// bring the last window to top
				const topWindow = this.zIndexes[this.zIndexes.length - 1];
				topWindow && this.moveWindowToTop(topWindow);
			}, 500);
		},

		closeAllWindows() {
			this.windows.forEach((win, index) => {
				setTimeout(() => {
					this.closeWindow(win.data.id);
				}, index * 200);
			});
		},

		reframe(winId) {
			// console.log('reframe', winId);

			const dialog = $('.modal#window-' + winId + ' .modal-dialog');
			if (dialog[0].offsetTop < this.constants.TOP_LIMIT) {
				dialog.css('top', 28 + 'px');
			}
		},

		moveWindowToTop(winId) {
			const win = this.windows.find((win) => win.id === winId);
			if (!win) {
				return;
			}
			this.current.win = win;
			const current = $('.modal#window-' + winId);
			$('.top-window').removeClass('top-window');
			current.addClass('top-window');
			this.reframe(winId);

			const windIndex = this.zIndexes.findIndex((id) => id === winId);
			this.zIndexes.splice(windIndex, 1);
			this.zIndexes.push(winId);

			this.zIndexes.forEach((id, index) => {
				const modal = $('.modal#window-' + id);
				modal.css('z-index', 1000 + index);
			});
		},

		getWindow(winId) {
			return this.windows.find((win) => win.id === winId);
		},

		getWindowByAppId(appId) {
			return this.windows.find((win) => win.data.id === appId);
		},

		toggle(id) {
			alert('App.toggle :: DEPRECATED');
			// this.view.toggle(id);
			// this.moveWindowToTop(id);
		},

		getView() {
			// console.log('getView', this.view);
			return this.view;
		},
		// trace(obj, name) {
		// 	for (const i in obj) {
		// 		if (obj[i] instanceof Function) {
		// 			const func = obj[i];
		// 			obj[i] = function () {
		// 				console.log(name + '.' + i);
		// 				return func.apply(obj, arguments);
		// 			};
		// 		}
		// 	}
		// }
	},

	async mounted() {
		// this.openApp(1);
		/^localhost/.test(document.location.hostname) && (window.app = this);
		await this.$store.dispatch('getPreferences');
		await this.$store.dispatch('getAppList');
		const prefs = await this.$store.dispatch('getPreferences');
		this.ready = true;

		console.log('App.mounted', prefs);
	}
};
</script>
<style>
@import url('~@/assets/css/style.css');
</style>
